// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Logout from "./Logout";
import Unauthorized from "./Unauthorized";
import ClientDashboard from "./roles/ClientDashboard";
import ProgrammerDashboard from "./roles/ProgrammerDashboard";
import GroupLeaderDashboard from "./roles/GroupLeaderDashboard";
import MasterDashboard from "./roles/MasterDashboard";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./forgotpassword";
import LoginRegister from "./loginRegister";
import SupportMB from "./roles/support";
import Profile from "./profiles/Profile";
import ProfilePage from "./profiles/ProfilePage";
import NotFound from "./profiles/NotFound";
import Based from "./based/based";
import HomePage from "./based/homePage";

function App() {
  const [isPC, setIsPC] = useState(true);

  // Function to check screen width
  const checkScreenSize = () => {
    setIsPC(window.innerWidth >= 768); // Adjust the breakpoint as needed
  };

  // Effect to set the initial state and add event listener for resizing
  useEffect(() => {
    checkScreenSize(); // Check screen size on mount
    window.addEventListener("resize", checkScreenSize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", checkScreenSize); // Clean up event listener
    };
  }, []);

  return (
    <h1 className="pc-message">SOON</h1>
  );

  if (!isPC) {
    return (
      <div className="pc-message">Please use a PC for the best experience.</div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/LRU" element={<Based />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="*" element={<NotFound />} />

        <Route element={<PrivateRoute allowedRoles={["client"]} />}>
          <Route path="/client" element={<ClientDashboard />} />
        </Route>
        <Route element={<PrivateRoute allowedRoles={["support"]} />}>
          <Route path="/support" element={<SupportMB />} />
        </Route>
        <Route element={<PrivateRoute allowedRoles={["master"]} />}>
          <Route path="/master" element={<MasterDashboard />} />
        </Route>
        <Route element={<PrivateRoute allowedRoles={["programmer"]} />}>
          <Route path="/programmer" element={<ProgrammerDashboard />} />
        </Route>
        <Route element={<PrivateRoute allowedRoles={["groupleader"]} />}>
          <Route path="/groupleader" element={<GroupLeaderDashboard />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
